.recipeList{
    width:60%;
    margin:auto;
    height:100vh;
}
.recipe-list-item{
    cursor:pointer;
}
.list-group-item{
    background-color: transparent; /* Set the initial background color */
    transition: background-color 0.3s; /* Add a smooth transition effect */
  
    /* Other styles for the list item */
  }
  
  .list-group-item:hover {
    background-color: tomato; /* Change the background color on hover */
  }