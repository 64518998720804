.recipeImage {
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.recipeImage {
    width: 500px;    /* Set a specific width */
    height: 400px;   /* Set a specific height */
    object-fit: cover; /* This property specifies how the image should be resized to fit its box */
    display: block;  /* Removes any potential extra space below the image */
    margin: 0 auto;  /* Centers the image within its container */
  }

/* YourComponent.css */
.accordion-container {
    width: 80%;
    margin:auto;
  }
  .accordion-button:not(.collapsed) {
    background-color: #E0E0E0 !important;
}
.accordion-button:focus{
    border:none !important;
}
  
  .accordion-header {
    background-color: #FFFFFF; /* Adjust the background color as needed */
    color: #FFD700 ; /* Text color */
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .accordion-body {
    padding: 15px;
    border: 1px solid #ccc; /* Border color */
    border-top: none;
  }
  
  .accordion-list {
    list-style-type: none;
    padding: 0;
  }
  
  .accordion-list-item {
    margin-bottom: 10px;
  }
  
  .accordion-list-item p {
    margin: 0;
  }
  
  /* Optional: Add transitions for smoother animations */
  .accordion-header,
  .accordion-body {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Hover effect for header */
  .accordion-header:hover {
    background-color:#FFFFFF; /* Adjust the background color on hover */
  }

  .recipeHeader{
    color: #FF6347;
    
  }
